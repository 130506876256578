.img-popup__description {
  color: #fff;
  text-align: start;
}

.img-popup__name {
  color: #fff;
  text-align: start;
}

.img-popup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: visibility 1s, opacity 1s;
    box-sizing: border-box;
    overflow:auto;
    z-index: 10;
}

.img-popup__opened {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: visible;
    opacity: 1;
    transition: visibility 1s, opacity 1s;
    overflow: auto;
    z-index: 99;
  }

.img-popup__picture-container {
    max-width: 90vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: fit-content;
    max-height: fit-content;
    overflow: auto;
    transition: visibility 1s, opacity 1s;
}

.img-popup__close-button {
  background-image: url(../../images/closeBtn.png);
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  transition: visibility 1s, opacity 1s;
  }
  
  .img-popup__close-button:hover {
    opacity: 60%;
    transition: 0.5s ease;
  }
  
  @media screen and (max-width: 600px) {
    .img-popup__close-button {
       width: 22px;
       height: 22px;
       margin-bottom: 16px;
    }
  }
.img-popup__picture {
    width: 100%;
    /*max-height: 90vh;*/
    margin-top: 8px;
    margin-right: 45px;
    object-fit: contain;
  }
  
  @media screen and (max-width: 600px) {
    .img-popup__picture {
       margin: 0;
    }
  }

  .navigation {
    visibility: hidden;
    transition: 0.3s;
  }
  
  .navigation__opened {
    visibility: visible;
    display: flex;
    justify-content: end;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,.3);
  }
  
  .contactsPopup__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    box-sizing: border-box;
    width: 200px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  
  .navigation_listcontainer {
      list-style-type: none;
      padding: 0;
      margin: auto;
      display: flex;
  }
  
  .navigation__list {
      text-align: center;
      list-style-type: none;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 28px;
      cursor: pointer;
      opacity: 1;
      transition: 0.3s ease;
  }
  
  @media screen and (max-width: 500px) {
      .navigation__list {
        margin-bottom: 24px;
      }
    }
  .navigation__listlink {
      text-decoration: none;
      color: black;
  }
  
  .navigation__listlink:hover {
      text-underline-offset: 4px;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      opacity: 0.7;
  }
  
  @media screen and (max-width: 500px) {
      .navigation__listlink:hover {
          text-underline-offset: 7px;
      }
    }
  
  .navigation__closebtn {
      position: absolute;
      z-index: 10;
      top: 3px;
      right: 3px;
      width: 32px;
      height: 32px;
      background-image: url(../../images/closeBtn.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
      border: none;
      background-color: #fff;
      opacity: 1;
      transition: 0.3s ease;
  }
  
  .navigation__closebtn:hover{
    opacity: 0.3;
    transition: 0.3s ease;
  }

  .contactsPopup__contact-item-link {
    font-size: 28px;
    color: #2f80ed;
    text-decoration: none;
    margin: 10px;
}

.contactsPopup__link {
  list-style-type: none;
  cursor: pointer;
}

.contactsPopup__link:hover {
  opacity: 0.7;
}

.infoHaifaPopup__container {
  background-color: #fff;
  max-width: 500px;
  max-height: fit-content;
  border-radius: 20px;
  padding: 30px;
  align-self: center;
  margin-top: 200px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .infoHaifaPopup__container {
    max-width: 80%;
    margin-top: 50px;
  }
}

.infoHaifaPopup__close-button {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background-image: url(../../images/closeBtn.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  border: none;
  background-color: #fff;
  opacity: 1;
  transition: 0.3s ease;
}

.infoHaifaPopup__bold-info {
  font-weight: bold;
}

.infoHaifaPopup-small-orange-star {
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 4;
  width: 30px;
  height: 30px;

  background-image: url(../../images/haifa/star.svg);
}

.infoHaifaPopup-orange-star {
  position: absolute;
  bottom: 50px;
  right: 10px;
  z-index: 4;
  width: 20px;
  height: 20px;

  background-image: url(../../images/haifa/star.svg);
}

.infoHaifaPopup-up-small-orange-star {
  position: absolute;
  top: 30px;
  left: 10px;
  z-index: 4;
  width: 25px;
  height: 25px;

  background-image: url(../../images/haifa/star.svg);
}

.infoHaifaPopup-up-orange-star {
  position: absolute;
  top: 20px;
  left: 40px;
  z-index: 4;
  width: 15px;
  height: 15px;

  background-image: url(../../images/haifa/star.svg);
}

.infoHaifaPopup__btn {
  border: 2px solid #FDD256;
  border-radius: 20px;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: auto;
  height: fit-content;
}

.infoHaifaPopup__btn:hover {
  background-color: #FDD256;
}


/*sukkot*/

.sukkotPopup__container {
  border-radius: 20px;
  box-sizing: border-box;
  align-self: center;
  margin-top: 50px;
  position: relative;
  z-index: 25;
}

@media screen and (max-width: 600px) {
  .sukkotPopup__container {
    margin-top: 25px;
  }
}

.sukkotPopup__img {
  position: relative;
}

@media screen and (max-width: 800px) {
  .sukkotPopup__img {
    max-width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .sukkotPopup__img {
    max-width: 350px;
  }
}

@media screen and (max-width: 370px) {
  .sukkotPopup__img {
    max-width: 300px;
  }
}

.sukkotPopup__close-button {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  opacity: 1;
  transition: 0.3s ease;
}

.sukkotPopup__ticket-btn {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  width: 250px;
  height: 45px;
  position: absolute;
  top: 30px;
  left: 30px;
  border: 2px solid #365004;
  background-color: #ff9601;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #365004;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}  

.sukkotPopup__ticket-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 550px) {
  .sukkotPopup__ticket-btn {
    left: 20px;
    width: 230px;
    height: 40px;
  }
}

@media screen and (max-width: 370px) {
  .sukkotPopup__ticket-btn {
    top: 20px;
    font-size: 16px;
    font-weight: 500;
  }
}

.sukkotPopup__event-btn {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  width: 230px;
  height: 45px;
  position: absolute;
  bottom: 160px;
  left: 30px;
  border: 2px solid #365004;
  background-color: #ff9601;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #365004;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sukkotPopup__event-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.lottery-popup__picture-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  height: fit-content;
  overflow: auto;
  transition: visibility 1s, opacity 1s;
}

.lottery-popup__picture {
  width: 100%;
  height: fit-content;
  overflow: auto;
  margin-top: 8px;
  margin-right: 0;
  object-fit: contain;
}

@media screen and (max-width: 550px) {
  .sukkotPopup__event-btn {
    left: 20px;
    width: 200px;
    height: 35px;
    bottom: 100px;
  }

  .lottery-popup__picture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    height: fit-content;
    overflow: auto;
    transition: visibility 1s, opacity 1s;
  }
}

@media screen and (max-width: 370px) {
  .sukkotPopup__event-btn {
    bottom: 80px;
    font-size: 16px;
    font-weight: 500;
  }
}