.participant-section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding: 0;
    padding-left: 43px;
    padding-right: 43px;
}

@media screen and (max-width: 900px) {
    .participant-section {
        width: calc(100% - 64px);
        padding: 32px;
    }
}

.participant-main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    max-width: 1200px;
    padding: 0;
}

.participant-main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .participant-main-title {
        font-size: 32px;
    }
}

@media screen and (max-width: 770px) {
    .participant-main-title {
        margin-bottom: 40px;
    }
}

.paticipant-container {
    list-style-type: none;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #f1f1f1;;
    width: 260px;
    height: 300px;
    padding: 10px;
    margin: 5px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.paticipant-container:hover {
    border: 3px solid #f1f1f1;
}

.participant-companyName-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.paticipant-companyName-description {
    width: 250px;
    height: 145px;
    /*overflow: ;*/
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    margin-right: 0;
    padding-right: 15px;
    text-align: start;
}

::-webkit-scrollbar {
    background-color: #fff; /* Цвет фона скроллбара */
    width: 5px; /* Ширина скроллбара */
  }
  
  /* Для Chrome, Safari и Opera */
  ::-webkit-scrollbar-thumb {
    background-color: #2f80ed; /* Цвет ползунка */
    background-color: #78e9b4;
    border-radius: 6px; /* Радиус закругления углов ползунка */
  }
  
  /* Для Firefox */
  /* Заменяем стандартный скроллбар на псевдоскроллбар */
  * {
    
    scrollbar-width: thin; /* Толщина скроллбара */
    scrollbar-color: #2f80ed #fff; /* Цвет ползунка и фона скроллбара */
    scrollbar-color: #78e9b4 #fff;
  }

  .paticipant-img {
      max-width: 200px;
      height: 125px;
      
      display: flex;
      margin: auto;
      margin-bottom: 20px;
      object-fit: contain;
  }

.participant-btn-add {
    border: 2px solid #2f80ed;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 50px;
}  

.participant-btn-add:hover {
    background-color: #2f80ed;
}

.participant-btn-add_notactive {
    border: 2px solid #b3bdc7;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 50px;
}