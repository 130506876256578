.schedule__section {
    background-color: #fff;
    width: calc(100% - 86px);
    padding: 43px;
}
.schedule__container {
    display: flex;
    position: relative;
    margin: auto;
}
.schedule__date {
    text-transform: uppercase;
    font-size: 120px;
    text-align: center;
    display: flex;
    margin: auto;
    margin-top: 200px;
    margin-bottom: 200px;
    font-weight: 200;
    cursor: pointer;
}
.schedule__date:hover {
    opacity: 0.7;
}
.schedule__title-btn__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.schedule__download-btn {
    width: fit-content;
    height: fit-content;
    padding: 15px 20px 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin: 0;
    color: #fff;
    background-color: #1563FF;
    border-radius: 12px;
    text-transform: uppercase;
    font-size: 24px;
    cursor: pointer;
    text-decoration: none;
}
.schedule__download-btn:hover {
    background-color: #7D26BF;
}
.schedule__triangle {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 49px;
    height: 43px;
    position: absolute;
}

.schedule__triangle_1{
    background-image: url(../../../../images/purim/purim2025/1.png);
    top: 100px;
    left: 200px;
}
.schedule__triangle_2{
    background-image: url(../../../../images/purim/purim2025/2.png);
    top: 300px;
    left: 150px;
}
.schedule__triangle_3{
    background-image: url(../../../../images/purim/purim2025/3.png);
    bottom: 50px;
    left: 500px;
}
.schedule__triangle_4{
    background-image: url(../../../../images/purim/purim2025/4.png);
    bottom: 50px;
    right: 450px;
}
.schedule__triangle_5{
    background-image: url(../../../../images/purim/purim2025/5.png);
    bottom: 100px;
    right: 300px;
}
.schedule__triangle_6{
    background-image: url(../../../../images/purim/purim2025/6.png);
    top: 100px;
    right: 200px;
}

@media screen and (max-width: 1250px) {
    .schedule__triangle_1{
        background-image: url(../../../../images/purim/purim2025/1.png);
        top: 100px;
        left: 100px;
    }
    .schedule__triangle_2{
        background-image: url(../../../../images/purim/purim2025/2.png);
        top: 300px;
        left: 50px;
    }
    .schedule__triangle_3{
        background-image: url(../../../../images/purim/purim2025/3.png);
        bottom: 50px;
        left: 400px;
    }
    .schedule__triangle_4{
        background-image: url(../../../../images/purim/purim2025/4.png);
        bottom: 50px;
        right: 350px;
    }
    .schedule__triangle_5{
        background-image: url(../../../../images/purim/purim2025/5.png);
        bottom: 100px;
        right: 200px;
    }
    .schedule__triangle_6{
        background-image: url(../../../../images/purim/purim2025/6.png);
        top: 100px;
        right: 100px;
    }
}
@media screen and (max-width: 1000px) {
    .schedule__triangle_1{
        background-image: url(../../../../images/purim/purim2025/1.png);
        top: 100px;
        left: 50px;
    }
    .schedule__triangle_2{
        background-image: url(../../../../images/purim/purim2025/2.png);
        top: 300px;
        left: 0px;
    }
    .schedule__triangle_3{
        background-image: url(../../../../images/purim/purim2025/3.png);
        bottom: 50px;
        left: 300px;
    }
    .schedule__triangle_4{
        background-image: url(../../../../images/purim/purim2025/4.png);
        bottom: 50px;
        right: 250px;
    }
    .schedule__triangle_5{
        background-image: url(../../../../images/purim/purim2025/5.png);
        bottom: 100px;
        right: 100px;
    }
    .schedule__triangle_6{
        background-image: url(../../../../images/purim/purim2025/6.png);
        top: 100px;
        right: 50px;
    }
}

@media screen and (max-width: 900px) {
    .schedule__download-btn {
        font-size: 18px;
        padding: 10px;
    }
    .schedule__section {
        width: calc(100% - 64px);
        padding: 32px;
    }
    .schedule__date {
        font-size: 75px;
    }
    .schedule__triangle {
        width: 36px;
        height: 38px;
    }
    .schedule__triangle_1{
        background-image: url(../../../../images/purim/purim2025/1.png);
        top: 100px;
        left: 50px;
    }
    .schedule__triangle_2{
        background-image: url(../../../../images/purim/purim2025/2.png);
        top: 300px;
        left: 0px;
    }
    .schedule__triangle_3{
        background-image: url(../../../../images/purim/purim2025/3.png);
        bottom: 50px;
        left: 250px;
    }
    .schedule__triangle_4{
        background-image: url(../../../../images/purim/purim2025/4.png);
        bottom: 50px;
        right: 200px;
    }
    .schedule__triangle_5{
        background-image: url(../../../../images/purim/purim2025/5.png);
        bottom: 100px;
        right: 100px;
    }
    .schedule__triangle_6{
        background-image: url(../../../../images/purim/purim2025/6.png);
        top: 100px;
        right: 50px;
    }
}

@media screen and (max-width: 700px) {
    .schedule__download-btn {
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
}

@media screen and (max-width: 650px) {

    .schedule__triangle {
        width: 16px;
        height: 17px;
    }
    .schedule__triangle_1{
        background-image: url(../../../../images/purim/purim2025/1.png);
        top: 100px;
        left: 50px;
    }
    .schedule__triangle_2{
        background-image: url(../../../../images/purim/purim2025/2.png);
        top: 300px;
        left: 0px;
    }
    .schedule__triangle_3{
        background-image: url(../../../../images/purim/purim2025/3.png);
        bottom: 50px;
        left: 250px;
    }
    .schedule__triangle_4{
        background-image: url(../../../../images/purim/purim2025/4.png);
        bottom: 50px;
        right: 200px;
    }
    .schedule__triangle_5{
        background-image: url(../../../../images/purim/purim2025/5.png);
        bottom: 100px;
        right: 100px;
    }
    .schedule__triangle_6{
        background-image: url(../../../../images/purim/purim2025/6.png);
        top: 100px;
        right: 50px;
    }
}

@media screen and (max-width: 400px) {
    .schedule__date {
        font-size: 36px;
    }

    .schedule__date {
        margin-top: 150px;
        margin-bottom: 150px;
    }

    .schedule__title-btn__container {
        flex-direction: column;
    }
    .schedule__download-btn { 
        align-self: flex-start;
        margin-top: 20px;
    }
}