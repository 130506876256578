.tickets-sticky-button {
    position: fixed;
    bottom: 60px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #9B62A2;
    background-color: #374BA5;*/
    text-decoration: none;
    color: #fff;
    font-size: 30px;
    font-family: 'Karton', sans-serif;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 150px;
    width: 150px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../../images/purim/purim2025/ticketsBTN.png);

    margin-bottom: 10px;
  }

   .lottery-sticky-button {
    position: fixed;
    bottom: 60px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9B62A2;
    background-color: #374BA5;
    text-decoration: none;
    color: #fff;
    font-size: 30px;
    font-family: 'Karton', sans-serif;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 150px;
    width: 150px;
   }

  @media screen and (max-width: 840px) {
    .tickets-sticky-button {
      height: 100px;
      width: 100px;
      font-size: 24px;
      /*right: 25px;*/
    }
    .lottery-sticky-button {
      height: 100px;
      width: 100px;
      font-size: 20px;
      right: 25px;
    }
}


  .lottery-sticky-button.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 55;
  }

  .tickets-sticky-button.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 55;
  }
  