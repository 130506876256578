.ourPartners__main-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: calc(100% - 86px);
    padding: 0;
    padding-left: 43px;
    padding-right: 43px;
    position: relative;
    background-color: #fff;
}

@media screen and (max-width: 900px) {
    .ourPartners__main-container {
        width: calc(100% - 64px);
        padding: 32px;
    }
}

.ourPartners__main-title {
    font-weight: bold;
    font-size: 32px;
    text-align: start;
    margin-top: 0;
    margin-bottom: 80px;
}

@media screen and (max-width: 1065px) {
    .ourPartners__main-title {
        font-size: 32px;
        
    }
}

@media screen and (max-width: 770px) {
    .ourPartners__main-title {
        margin-bottom: 40px;
    }
}

.ourPartners__logo-wrapper {
    list-style-type: none;
    margin: 50px 100px 50px 100px;
    
}

@media screen and (max-width:1300px) {
    .ourPartners__logo-wrapper {
        margin: 50px;
    }
}

.ourPartners__logo-container {
    object-fit: fill;
    text-decoration: none;
}    

.ourPartners__img {
    max-width: 200px;
    max-height: 200px;
    object-fit: fill;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .ourPartners__img {
        max-width: 150px;
        max-height: 150px;
    }
}

@media screen and (max-width: 545px) {
    .ourPartners__img {
        max-width: 100px;
        max-height: 100px;
    }
}

.ourPartners__wrapper {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

.ourPartners__triangles_up {
    position: absolute;
    top: -50px;
    right: 50px;
    background-image: url(../../images/purim/purim2025/ourpartnersup.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 373px;
    height: 171px;
}

.ourPartners__triangles_down {
    position: absolute;
    bottom: -50px;
    left: 50px;
    background-image: url(../../images/purim/purim2025/ourpartnersdown.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    width: 448px;
    height: 145px;
}

@media screen and (max-width: 900px) {
    .ourPartners__triangles_up {
        width: 202px;
        height: 93px;
    }
    
    .ourPartners__triangles_down {
        width: 307px;
        height: 100px;
    }
}

@media screen and (max-width: 700px) {
    .ourPartners__triangles_down {
        width: 235px;
        height: 81px;
        bottom: -20px;
    }

    .ourPartners__triangles_up {
        top: -30px;
    }
}